import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "doc" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["imageWithFallback"], {
      alt: "Cover Image of Document",
      src: $setup.coverImage,
      "fallback-src": $setup.genericThumbUrl
    }, null, 8 /* PROPS */, ["src"])
  ]))
}