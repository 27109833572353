<script lang="ts" setup>
  import type { Nil } from '@mcwd/typescript-type-guards';
  import imageWithFallback from '../../../../shared-components/image-with-fallback.vue';

  const props = defineProps<{
    customData?: Record<string, any>;
  }>();

  const coverImage = props.customData?.coverImage as string | Nil;
  const genericThumbUrl = "https://static.mastercontrol.com/assets/persist/images/document-thumbnail-generic.png";
</script>

<template>
  <div class="doc">
    <imageWithFallback 
      alt="Cover Image of Document"
      :src="coverImage"
      :fallback-src="genericThumbUrl"
    />
  </div>
</template>