<script lang="ts" setup>
  const props = defineProps<{
    customData?: Record<string, any>;
  }>();
  const data = props.customData ?? { bulletList: '' } as { bulletList: string };
</script>

<template>
  <div v-if="data.bulletList !== ''" class="bullet-item-list">
    <h5>What You'll Learn</h5>
    <ul class="list" v-html="data.bulletList"></ul>
  </div>
</template>