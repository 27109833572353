<script lang="ts" setup>
  const props = defineProps<{
    customData?: Record<string, any>;
  }>();
  const data = props.customData as Record<string, any>;
</script>

<template>
  <div class="doc-info col-xl-10">
    <h5>About This Document</h5>
    <p v-html="data.summary"></p>
    <div class="document-download-info">
      <i class="far fa-file-pdf"></i>
      <div v-if="data.fileFormat !== ''" class="file-type-name">
        {{ data.fileFormat }}
      </div>
      <div v-if="data.fileSize !== ''" class="file-size">
        <span class="mx-3">—</span>
        {{ data.fileSize }}
      </div>
    </div>
  </div>
</template>